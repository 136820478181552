<template>
  <b-modal
    id="procedure_form_modal"
    hide-header
    hide-footer
    centered size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
      <div class="modal-header">
          <div class="modal-title">
            <span v-if="!procedureEdit">
              Adicionar procedimento
            </span>

            <span v-else>
              Editar procedimento
            </span>

          </div>
          <span class="icon-box"><Close class="icon stroke" @click="closeModal"  /></span>
      </div>

      <div class="around-content">
          <b-row >
            <b-col cols="3">
              <label for="type" class="title-styles">Tipo</label>
              <multiselect
                v-model="form.type"
                placeholder="Selecionar"
                :options="types"
                label="label"
                track-by="value"
                :option-height="40"
                :showLabels="false"
                :searchable="true"
                :internal-search="false"
                class="with-border"
                :disabled="procedureEdit && !edit"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noResult"> Nenhum resultado </template>
              </multiselect>
              <div v-if="validated && !form.type" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="6">
              <label for="procedure_id" class="title-styles">Procedimento</label>
              <ItemSelect
                :value="form.item"
                :types="proceduresTypes"
                :disabled="procedureEdit && !edit"
                @select="setProcedure"
                placeholder="Pesquisar procedimento"
                :hasCreateItem="false"
              />
              <div v-if="validated && !form.item" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="3" class="d-flex align-items-end justify-content-end">
              <b-button
                v-if="!procedureEdit && !edit"
                variant="primary"
                class="add-button"
                size="lg"
                @click="addProcedure"
              >
                Adicionar
              </b-button>

              <b-button
                v-else-if="!procedureEdit && edit"
                variant="primary"
                class="add-button"
                size="lg"
                @click="updateProcedure"
                :disabled="!edit"
              >
                Editar
              </b-button>

              <b-button
                v-else-if="procedureEdit"
                variant="primary"
                class="add-button"
                size="lg"
                @click="updateProcedure"
                :disabled="!edit"
              >
                Editar
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mt-2" v-if="!procedureEdit">
            <b-col
              cols="12"
              class="mb-3"
              v-for="(procedure, index) of procedures" :key="index"
            >
              <ProcedureCard
                :procedure="procedure"
                :validated="validatedIntervals"
                :days="days"
                @delete-procedure="deleteProcedure(index)"
                @edit-procedure="editProcedure(procedure, index)"
              />
            </b-col>
          </b-row>

          <b-row class="mt-2" v-else>
            <b-col
              cols="12"
              class="mb-3"
            >
              <ProcedureCard
                :procedure="procEdit"
                :validated="validatedIntervals"
                :days="days"
                @deleteProcedure="deleteProcedure(index)"
                @edit-procedure="editProcedure(procEdit, null)"
                :edit="true"
              />
            </b-col>
          </b-row>

          <div
            v-can="'AgOnlProc2'"
            class="wrapper-button"
          >
            <b-button
              :disabled="!procedures.length"
              v-if="!procedureEdit"
              variant="primary"
              size="lg"
              @click="createPublicScheduleProfessionalProcedures"
            >
              Salvar procedimentos
            </b-button>

            <b-button
              v-else
              variant="primary"
              size="lg"
              @click="updatePublicScheduleProfessionalProcedures"
            >
              Alterar procedimentos
            </b-button>
          </div>
      </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'ProcedureFormModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ProcedureCard: () => import('./../Modules/ProcedureCard.vue'),
    ItemSelect: () => import('@/modules/items/components/ItemSelect.vue')
  },
  props: {
    professional: Object,
    procedureEdit: Object
  },

  data() {
    return {
      clinic: getCurrentClinic(),

      types: [
        { label: 'Consulta', value: 'APPOINTMENT' },
        { label: 'Exame', value: 'EXAM' },
        { label: 'Retorno', value: 'RETURN' },
        { label: 'Teleatendimento', value: 'TELEMEDICINE' },
      ],
      proceduresTypes: null,

      clinicProcedures: [],
      form: this.getDefaultForm(),
      validated: false,
      validatedIntervals: false,

      days: [],
      procedures: [],

      edit: false,
      index: null,

      procEdit: {}
    }
  },
  methods: {
    onShow(){
      this.form = this.getDefaultForm()
      this.clinicProcedures = []
    },

    closeModal(){
      this.form = this.getDefaultForm()
      this.validated = false;
      this.edit = false
      this.procedures = []
      this.$bvModal.hide('procedure_form_modal');
    },

    getDefaultForm() {
      return {
        type: null,
        item: null
      }
    },

    fillTussOptions(data) {
      const result = [];
      data.forEach(el => {
        const procedure = el.procedure_tuss_id ? el.procedure_tuss : el.procedure_sus
        procedure.id = el.id
        result.push(procedure)
      })
      return result;
    },

    setProcedure(item){
      if (item) {
        this.form.item = item
      }
    },

    async getScheduleConfigDay() {
      this.days = []
      for (let index = 0; index < 7; index++) {
        this.days.push({
          'weekDay': index,
          'active': false,
          'intervals': []
        })
      }

      try {
        const res = await this.api.getClinicScheduleConfig(this.clinic.id)
        const response = await this.api.getDoctorScheduleConfig(this.clinic.id, this.professional.id)
        response.data.configs.days.forEach(day => {
          let index

          switch (day.day){
            case 'DOMINGO':
              index = 0
              break;
            case 'SEGUNDA-FEIRA':
              index = 1
              break;
            case 'TERCA-FEIRA':
              index = 2
              break;
            case 'QUARTA-FEIRA':
              index = 3
              break;
            case 'QUINTA-FEIRA':
              index = 4
              break;
            case 'SEXTA-FEIRA':
              index = 5
              break;
            case 'SABADO':
              index = 6
              break;
          }

          const professionalDay = res.data.configs.days.find(option => option.day === day.day)
          this.days[index] = {
            'weekDay': index,
            'active': day.status && professionalDay.status,
            'intervals': !day.schedule_config_day_intervals && professionalDay.status ? null : day.schedule_config_day_intervals.map(interval => {
              return {
                'value': interval.id,
                'label': interval.start_time.slice(0,5)+ ' - ' +interval.end_time.slice(0,5)
              }
            }).sort((a , b) => {
              if(a.label < b.label) return -1;
              else return 1
            })
          };
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },

    addProcedure(){
      this.validated = true
      const isLoading = this.$loading.show()

      try {
        if(!(this.form.item && this.form.type)) return
        this.procedures.push({
          'item': this.form.item,
          'item_id': this.form.item.id,
          'type': this.form.type.label,
          'intervals' : [
            {
              'day': 0,
              'intervals': [],
              'active': this.days[0]?.active && this.days[0]?.intervals?.length
            },
            {
              'day': 1,
              'intervals': [],
              'active': this.days[1]?.active && this.days[1]?.intervals?.length
            },
            {
              'day': 2,
              'intervals': [],
              'active': this.days[2]?.active && this.days[2]?.intervals?.length
            },
            {
              'day': 3,
              'intervals': [],
              'active': this.days[3]?.active && this.days[3]?.intervals?.length
            },
            {
              'day': 4,
              'intervals': [],
              'active': this.days[4]?.active && this.days[4]?.intervals?.length
            },
            {
              'day': 5,
              'intervals': [],
              'active': this.days[5]?.active && this.days[5]?.intervals?.length
            },
            {
              'day': 6,
              'intervals': [],
              'active': this.days[6]?.active && this.days[6]?.intervals?.length
            }
          ]
        })
        this.validated = false
        this.form = this.getDefaultForm()
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },

    editProcedure(procedure, index){
      this.form.item = procedure.item
      procedure = this.types.find(p => p.label === procedure.type)
      this.form.type = procedure
      if(!this.procedureEdit){
        this.index = index
      }

      this.edit = true
    },

    updateProcedure(){
      if(this.procedureEdit){
        this.procEdit.type = this.form.type.label
        if(this.procEdit.item_id !== this.form.item.id){
          this.procEdit.item_id = this.form.item.id
          this.procEdit.item = this.form.item
        }
      }

      else{
        this.procedures[this.index].type = this.form.type
        this.procedures[this.index].item_id = this.form.item.id
        this.procedures[this.index].item = this.form.item
      }
      this.edit = false
      this.form = this.getDefaultForm()
    },

    deleteProcedure(index){
      this.procedures.splice(index, 1);
    },

    validateProcedures(){
      let result = true;
      this.procedures.forEach(procedure => {
        procedure.intervals.forEach(interval => {
          if(interval.active && interval.intervals.length === 0) result = false
        });
      });
      return result;
    },

    async createPublicScheduleProfessionalProcedures(){
      this.validatedIntervals = true
      if(!this.validateProcedures()) return this.$toast.warning('É necessário definir periodo em todos os dias ativos para salvar o procedimento');
      const isLoading = this.$loading.show()
      try {
        const data = {
          'pub_sched_prof_id': this.professional.public_schedule_professional.id,
          'procedures': this.procedures
        }
        await this.api.createPublicScheduleProfessionalProcedures(data);
        this.$toast.success('Procedimentos do profissional no agendamento online criado com sucesso!');
        this.$emit('reload-procedures')
        this.closeModal()
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
        this.validated = false
      }
    },

    async updatePublicScheduleProfessionalProcedures(){
      this.validatedIntervals = true
      if(!this.validateProcedures()) return this.$toast.error('É necessário definir periodo em todos os dias ativos para salvar o procedimento');
      const isLoading = this.$loading.show();
      try {
        await this.api.updatePublicScheduleProfessionalProcedures(this.procEdit.id, this.procEdit);
        this.$toast.success('Procedimentos do profissional no agendamento online editado com sucesso!');
        this.$emit('reload-procedures')
        this.closeModal()
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    }
  },
  watch: {
    'professional': {
      async handler(newValue) {
        if(newValue){
          await this.getScheduleConfigDay()
        }
      },
      immediate: true
    },
    'procedureEdit': {
      handler(newValue) {
        if(newValue){
          this.procEdit = newValue
          this.procEdit.intervals = [
            {
              'day': this.days[0].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 0).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 0).map(interval => {
                    return {
                      'value': interval.day_interval.id,
                      'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                    }
                }) :
                [],
              'active': this.days[0].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 0).length)
            },
            {
              'day': this.days[1].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 1).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 1).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[1].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 1).length)
            },
            {
              'day': this.days[2].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 2).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 2).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[2].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 2).length)
            },
            {
              'day': this.days[3].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 3).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 3).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[3].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 3).length)
            },
            {
              'day': this.days[4].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 4).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 4).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[4].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 4).length)
            },
            {
              'day': this.days[5].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 5).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 5).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[5].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 5).length)
            },
            {
              'day': this.days[6].weekDay,
              'intervals': newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 6).length ?
                newValue.pub_schedule_intervals.filter(el => el.day === 6).map(interval => {
                  return {
                    'value': interval.day_interval.id,
                    'label': interval.day_interval.start_time.slice(0,5)+ ' - ' +interval.day_interval.end_time.slice(0,5)
                  }
                }) :
                [],
              'active': this.days[6].active && (newValue.pub_schedule_intervals && newValue.pub_schedule_intervals.filter(el => el.day === 6).length)
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    'form.type': {
      async handler(newValue) {
        if(newValue){
          if(this.form.type) {
            this.proceduresTypes = [this.form.type.value]
          }
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss">
#procedure_form_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-000);
    }
  }

  .add-button {
    width: 100%;
    margin-top: 23px;
  }

  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin-bottom: 4px !important;
  }

  .form-title{
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }
}
</style>
